import Branding1 from "./Image/Branding/AICDP1.jpg";
import Branding2 from "./Image/Branding/Apurejoy Essential Oils.png";
import Branding3 from "./Image/Branding/Bargain Homes.png";
import Branding4 from "./Image/Branding/BRIDGE EASY FINAL-01.png";
import Branding5 from "./Image/Branding/Carors.jpg";
import Branding6 from "./Image/Branding/D A Artistry.png";
import Branding7 from "./Image/Branding/dauntless3.png";
import Branding8 from "./Image/Branding/Dhananjay Dhamankar.jpg";
import Branding9 from "./Image/Branding/Dr. Usha Nair.jpg";
import Branding10 from "./Image/Branding/Dreams Company.png";
import Branding11 from "./Image/Branding/Early Nest Preschool.png";
import Branding12 from "./Image/Branding/Ek Plate Parcel.jpg";
import Branding13 from "./Image/Branding/ELC.png";
import Branding14 from "./Image/Branding/endeavour Homes.png";
import Branding15 from "./Image/Branding/Endeavour Nidhi.png";
import Branding16 from "./Image/Branding/Endeavour Speech.png";
import Branding17 from "./Image/Branding/Enrollit.png";
import Branding18 from "./Image/Branding/Excel Commerce Tutorials.png";
import Branding19 from "./Image/Branding/ExeEdge.png";
import Branding20 from "./Image/Branding/First Day First Show.png";
import Branding21 from "./Image/Branding/Logo1.png";
import Branding22 from "./Image/Branding/Gruhniti.png";
import Branding23 from "./Image/Branding/iAspireIT.jpg";
import Branding24 from "./Image/Branding/Istha Vastu Parichay.png";
import Branding25 from "./Image/Branding/Juscliq.png";
import Branding26 from "./Image/Branding/K K Legal.png";
import Branding27 from "./Image/Branding/Kavitake General.png";
import Branding28 from "./Image/Branding/KDOC Accounting.jpg";
import Branding29 from "./Image/Branding/La Camellia.png";
import Branding30 from "./Image/Branding/Leafy Meadows.png";
import Branding31 from "./Image/Branding/Madhavbaug Aundh.png";
import Branding32 from "./Image/Branding/Neeta developers.png";
import Branding33 from "./Image/Branding/NEOCRETE BLOCKS.png";
import Branding34 from "./Image/Branding/Networth Axen LLP.png";
import Branding35 from "./Image/Branding/One Place Property.png";
import Branding36 from "./Image/Branding/Outsource Hub.jpg";
import Branding37 from "./Image/Branding/PNP Tea Company.png";
import Branding38 from "./Image/Branding/Prakriti Natural Tea.png";
import Branding39 from "./Image/Branding/Pratikrut.jpg";
import Branding40 from "./Image/Branding/Rajmudra Agro Developers.png";
import Branding41 from "./Image/Branding/Rajmudra Agro Developers.png";
import Branding42 from "./Image/Branding/Rcountry Resorts.png";
import Branding43 from "./Image/Branding/Riddham Valley.png";
import Branding44 from "./Image/Branding/Rotary AIR.jpg";
import Branding45 from "./Image/Branding/Royal Black Rice.jpg";
import Branding46 from "./Image/Branding/Sagrasangeet.jpg";
import Branding47 from "./Image/Branding/Saksham Properties.png";
import Branding48 from "./Image/Branding/Saptarshi Sanskar Foundation.jpg";
import Branding49 from "./Image/Branding/Shops & Offices.png";
import Branding50 from "./Image/Branding/Siddhidata.png";
import Branding51 from "./Image/Branding/Siya Scientific.png";
import Branding52 from "./Image/Branding/Sky Ventures.png";
import Branding53 from "./Image/Branding/Sparsh.png";
import Branding54 from "./Image/Branding/Sportality.png";
import Branding55 from "./Image/Branding/SRS PROPERTIES.png";
import Branding56 from "./Image/Branding/Tathastu Properties.png";
import Branding57 from "./Image/Branding/The Strat Collective.jpg";
import Branding58 from "./Image/Branding/Vikas Travels.png";
import Branding59 from "./Image/Branding/Vishwa Agarbatti.png";
import Branding60 from "./Image/Branding/Waman Developers.png";
import Branding61 from "./Image/Branding/wanderer's.png";
import Branding62 from "./Image/Branding/WeConnect Ventures.png";
import Branding63 from "./Image/Branding/White India.jpg";
import Branding64 from "./Image/Branding/Zoa Retreat.png";
export const projectsData = [
  { id: 1, name: "Project A", category: "Branding", img: Branding1 },
  { id: 2, name: "Project B", category: "Branding", img: Branding2 },
  { id: 3, name: "Project B", category: "Branding", img: Branding3 },
  { id: 4, name: "Project B", category: "Branding", img: Branding4 },
  { id: 5, name: "Project B", category: "Branding", img: Branding5 },
  { id: 6, name: "Project B", category: "Branding", img: Branding6 },
  { id: 7, name: "Project B", category: "Branding", img: Branding7 },
  { id: 8, name: "Project B", category: "Branding", img: Branding8 },
  { id: 9, name: "Project B", category: "Branding", img: Branding9 },
  { id: 10, name: "Project B", category: "Branding", img: Branding10 },
  { id: 11, name: "Project B", category: "Branding", img: Branding11 },
  { id: 12, name: "Project B", category: "Branding", img: Branding12 },
  { id: 13, name: "Project B", category: "Branding", img: Branding13 },
  { id: 14, name: "Project B", category: "Branding", img: Branding14 },
  { id: 15, name: "Project B", category: "Branding", img: Branding15 },
  { id: 16, name: "Project B", category: "Branding", img: Branding16 },
  { id: 17, name: "Project B", category: "Branding", img: Branding17 },
  { id: 18, name: "Project B", category: "Branding", img: Branding18 },
  { id: 19, name: "Project B", category: "Branding", img: Branding19 },
  { id: 20, name: "Project B", category: "Branding", img: Branding20 },
  { id: 21, name: "Project B", category: "Branding", img: Branding21 },
  { id: 22, name: "Project B", category: "Branding", img: Branding22 },
  { id: 23, name: "Project B", category: "Branding", img: Branding23 },
  { id: 24, name: "Project B", category: "Branding", img: Branding24 },
  { id: 25, name: "Project B", category: "Branding", img: Branding25 },
  { id: 26, name: "Project B", category: "Branding", img: Branding26 },
  { id: 27, name: "Project B", category: "Branding", img: Branding27 },
  { id: 28, name: "Project B", category: "Branding", img: Branding28 },
  { id: 29, name: "Project B", category: "Branding", img: Branding29 },
  { id: 30, name: "Project B", category: "Branding", img: Branding30 },
  { id: 31, name: "Project B", category: "Branding", img: Branding31 },
  { id: 32, name: "Project B", category: "Branding", img: Branding32 },
  { id: 33, name: "Project B", category: "Branding", img: Branding33 },
  { id: 34, name: "Project B", category: "Branding", img: Branding34 },
  { id: 35, name: "Project B", category: "Branding", img: Branding35 },
  { id: 36, name: "Project B", category: "Branding", img: Branding36 },
  { id: 37, name: "Project B", category: "Branding", img: Branding37 },
  { id: 38, name: "Project B", category: "Branding", img: Branding38 },
  { id: 39, name: "Project B", category: "Branding", img: Branding39 },
  { id: 40, name: "Project B", category: "Branding", img: Branding40 },
 
  { id: 42, name: "Project B", category: "Branding", img: Branding42 },
  { id: 43, name: "Project B", category: "Branding", img: Branding43 },
  { id: 44, name: "Project B", category: "Branding", img: Branding44 },
  { id: 45, name: "Project B", category: "Branding", img: Branding45 },
  { id: 46, name: "Project B", category: "Branding", img: Branding46 },
  { id: 47, name: "Project B", category: "Branding", img: Branding47 },
  { id: 48, name: "Project B", category: "Branding", img: Branding48 },
  { id: 49, name: "Project B", category: "Branding", img: Branding49 },
  { id: 50, name: "Project B", category: "Branding", img: Branding50 },
  { id: 51, name: "Project B", category: "Branding", img: Branding51 },
  { id: 52, name: "Project B", category: "Branding", img: Branding52 },
  { id: 53, name: "Project B", category: "Branding", img: Branding53 },
  { id: 54, name: "Project B", category: "Branding", img: Branding54 },
  { id: 55, name: "Project B", category: "Branding", img: Branding55 },
  { id: 56, name: "Project B", category: "Branding", img: Branding46 },
  { id: 57, name: "Project B", category: "Branding", img: Branding57 },
  { id: 58, name: "Project B", category: "Branding", img: Branding58 },
  { id: 59, name: "Project B", category: "Branding", img: Branding59 },
  { id: 60, name: "Project B", category: "Branding", img: Branding60 },
  { id: 61, name: "Project B", category: "Branding", img: Branding61 },
  { id: 62, name: "Project B", category: "Branding", img: Branding62 },
  { id: 63, name: "Project B", category: "Branding", img: Branding63 },
  { id: 64, name: "Project B", category: "Branding", img: Branding64 },


  // { id: 3, name: "Project C", category: "Websites", img: Branding1 },
  // { id: 4, name: "Project D", category: "Graphic Design", img: Branding1 },
  // {
  //   id: 5,
  //   name: "Project E",
  //   category: "Video",
  // },
  // { id: 6, name: "Project C", category: "Websites" },
  // { id: 7, name: "Project D", category: "Graphic Design" },
  // { id: 8, name: "Project E", category: "Video" },
  // Add more projects with different categories
];
