export const cardData1 = {
    front: {
      name: 'Light',
      serviceList : [
                    'Web Audit',
                 "Social Media Optimization",
                    "Three Social Media Campaigns",
                    "One Google Adword Campaign",
                    ' <s>SEO and Web Support </s>',
                    '  <s>Keyword Research </s>',
                      ' <s>Creating Backlincks </s>',
                       ' <s>Email Marketing </s>',
                        ' <s>SMS Marketing </s>',
                        '  <s>Web Development </s>',
                      ' <s>Marketing Research </s>',
                       ' <s>Graphics and DTP Soultions </s>',
                      '  <s>Web Development </s>',
                    ' <s>Online Reputation Management </s>',
                   ' <s>Conventional Marketing </s>',
      ]
    },
    back: {
      price: '₹ 35,000',
    },
  };
  
  export const cardData2 = {
    front: {
      name: 'Basic',
      serviceList : [
                    'Web Audit',
                 "Social Media Optimization",
                    "Three Social Media Campaigns",
                    "One Google Adword Campaign",
                    ' SEO and Web Support ',
                    '  Keyword Research ',
                      ' Creating Backlincks ',
                       ' <s>Email Marketing </s>',
                        ' <s>SMS Marketing </s>',
                        '  <s>Web Development </s>',
                      ' <s>Marketing Research </s>',
                       ' <s>Graphics and DTP Soultions </s>',
                      '  <s>Web Development </s>',
                    ' <s>Online Reputation Management </s>',
                   ' <s>Conventional Marketing </s>',
      ]
    },
    back: {
      price: '₹ 55,000',
    },
  };

  export const cardData3 = {
    front: {
      name: 'Advanced',
      serviceList : [
                    'Web Audit',
                 "Social Media Optimization",
                    "Three Social Media Campaigns",
                    "One Google Adword Campaign",
                    ' SEO and Web Support ',
                    '  Keyword Research ',
                      ' Creating Backlincks ',
                       ' Email Marketing ',
                        ' SMS Marketing ',
                        '  Web Development ',
                      ' Marketing Research ',
                       ' <s>Graphics and DTP Soultions </s>',
                      '  <s>Web Development </s>',
                    ' <s>Online Reputation Management </s>',
                   ' <s>Conventional Marketing </s>',
      ]
    },
    back: {
      price: '₹ 55,000',
    },
  };

  export const cardData4 = {
    front: {
      name: 'Ultimate',
      serviceList : [
                    'Web Audit',
                 "Social Media Optimization",
                    "Three Social Media Campaigns",
                    "One Google Adword Campaign",
                    ' SEO and Web Support ',
                    '  Keyword Research ',
                      ' Creating Backlincks ',
                       ' Email Marketing ',
                        ' SMS Marketing ',
                        '  Web Development ',
                      ' Marketing Research ',
                       ' Graphics and DTP Soultions ',
                      '  Web Development ',
                    ' Online Reputation Management ',
                   ' Conventional Marketing ',
      ]
    },
    back: {
      price: '₹ 1,20,000',
    },
  };
  