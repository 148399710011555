export const page1Data = {
    title: 'Inbound Marketing: New frontier in marketing businesses.',
    name: '02 Mar 2018 Sharad Koche',
    para1: `Sam said You know there is nothing that I am not doing, to get more consumers to buy my products.
    Well have you tried to search why are they not attracted to buy your products?
    Have you tried creating engaging content and providing solutions rather than showing problems? Replied Mark.
    <br>
    This is a typical conversation between any entrepreneur who has a great product but has no idea about the concept of inbound marketing.
    <br>
    Many people who are good at creating great innovations and inventions need to amplify the necessity that made them create the product.
    <br>
    For they are not alone who face that necessity, but there are millions who face such necessities and to offer the solution, you need to spread the message that will fullfil the necessity.The way to convey such messages is in corporated in inbound marketing strategy.
    <br>
    There are many examples in history that convey the above message strongly, take an example of any great invention that was invented around the time when there was a need for a solution and you will find that it was only successful as it reached to the masses and satisfied their ardent need.
    <br>
    Today Inbound Marketing plays a vital role in assisting you to answer the above question.
    <br>
    There are millions of people online searching to fulfill their needs all you need is to present a solution to that needs in structured and simplified way.
    <br>
    Endeavour Marketing is the only marketing company that provides research-based solutions. We offer exhaustive market research combined with some of the powerful inbound marketing tools.`,
    para2: ` Many people who are good at creating great innovations and inventions need to amplify the necessity that made them create the product.
    <br>
    For they are not alone who face that necessity, but there are millions who face such necessities and to offer the solution, you need to spread the message that will fullfil the necessity.The way to convey such messages is in corporated in inbound marketing strategy.
    <br>
    There are many examples in history that convey the above message strongly, take an example of any great invention that was invented around the time when there was a need for a solution and you will find that it was only successful as it reached to the masses and satisfied their ardent need.
    <br>
    Today Inbound Marketing plays a vital role in assisting you to answer the above question.
    <br>
    There are millions of people online searching to fulfill their needs all you need is to present a solution to that needs in structured and simplified way.
    <br>
    Endeavour Marketing is the only marketing company that provides research-based solutions. We offer exhaustive market research combined with some of the powerful inbound marketing tools`,
    img:'https://cdn.pixabay.com/photo/2019/06/19/07/13/email-4284157_1280.png',
    img1:'https://cdn.pixabay.com/photo/2019/06/19/07/13/email-4284157_1280.png',
    img2:'https://cdn.pixabay.com/photo/2019/06/19/07/13/email-4284157_1280.png',
    recent1:'research combined with some of the powerful inbound marketing tools',
    recent2:'research combined with some of the powerful inbound marketing tools',
    next:'',
    privious:''
  };